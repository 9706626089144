import * as React from 'react'

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Chip from '@mui/material/Chip';
import Hidden from '@mui/material/Hidden';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Layout from '../components/layout'
import {
    paper,
    chips
} from './experience.module.css'

const ExperiencePage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Layout pageTitle="About me">
            <Container maxWidth="lg">
                <Paper elevation={3}>
                    <Box p={isMobile ? 2 : 10}>
                        <Typography variant="h4" paragraph="true" color="primary">Experience</Typography>
                        <Typography align="justify" paragraph="true">More than 12 years of experience as a Software Engineer working with Java and Open Source frameworks.</Typography>
                    </Box>
                    <Hidden smDown>
                        <Timeline position="alternate">
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Box p={0.5}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            2017 - Today
                                        </Typography>
                                    </Box>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot >
                                        <DashboardOutlinedIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={paper}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            Globant - Software Architect
                                        </Typography>
                                        <Box p={1}>
                                            <Typography variant="h6">Disney</Typography>
                                            <Typography variant="body2" >Working on Cloud-based application development to integrate different Disney Parks backend services with third-party services for Labor System management.</Typography>
                                            <Chip label="Google Cloud Platform" size="small" className={chips} />
                                            <Chip label="Google App Engine" size="small" className={chips} />
                                            <Chip label="Google Kubernetes Engine" size="small" className={chips} />
                                            <Chip label="Firestore" size="small" className={chips} />
                                            <Chip label="Google Pub/Sub" size="small" className={chips} />
                                            <Chip label="Google Storage" size="small" className={chips} />
                                            <Chip label="Java11" size="small" className={chips} />
                                            <Chip label="Spring Cloud" size="small" className={chips} />
                                            <Chip label="Microservices" size="small" className={chips} />
                                        </Box>
                                        <Box p={1}>
                                            <Typography variant="subtitle2">AEP Energy USA</Typography>
                                            <Typography variant="body2">Monolith to Microservices migration for an eCommerce system using open source. Microservices development with Spring Cloud.</Typography>
                                            <Chip label="Microservices" size="small" className={chips} />
                                            <Chip label="SpringBoot" size="small" className={chips} />
                                            <Chip label="SpringCloud" size="small" className={chips} />
                                            <Chip label="Swagger" size="small" className={chips} />
                                            <Chip label="Distributed Tracing" size="small" className={chips} />
                                            <Chip label="Docker" size="small" className={chips} />
                                        </Box>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Box p={0.5}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            2016 - 2017
                                        </Typography>
                                    </Box>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot >
                                        <DashboardOutlinedIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={paper}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            Sapia (Cosapi Data) - Consultant
                                        </Typography>
                                        <Box p={1}>
                                            <Typography variant="h6">SUNAT</Typography>
                                            <Typography variant="body2">REST microservices development. Html 5 frontend development sending requests to microservices backend. Visa payment platform. Coaching to the development team to improve their development skills.</Typography>
                                            <Chip label="Dropwizard" size="small" className={chips} />
                                            <Chip label="Docker" size="small" className={chips} />
                                            <Chip label="Kubernetes" size="small" className={chips} />
                                            <Chip label="AngularJS" size="small" className={chips} />
                                            <Chip label="JQuery" size="small" className={chips} />
                                            <Chip label="Bootstrap" size="small" className={chips} />
                                            <Chip label="Nginx" size="small" className={chips} />
                                        </Box>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Box p={0.5}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            2014 - 2016
                                        </Typography>
                                    </Box>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot >
                                        <DashboardOutlinedIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={paper}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            Ministerio de Economia del Perú - Consultant
                                        </Typography>
                                        <Box p={1}>
                                            <Typography variant="h6">SIAF 2</Typography>
                                            <Typography variant="body2">SOA architecture design and implementation with open source. System integrations with ESB.</Typography>
                                            <Chip label="SOA" size="small" className={chips} />
                                            <Chip label="Oracle" size="small" className={chips} />
                                            <Chip label="J2EE" size="small" className={chips} />
                                            <Chip label="Microservices" size="small" className={chips} />
                                            <Chip label="Drools" size="small" className={chips} />
                                        </Box>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Box p={0.5}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            2010 - 2013
                                        </Typography>
                                    </Box>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot >
                                        <DashboardOutlinedIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={paper}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            AJE Group - Team Lead
                                        </Typography>
                                        <Box p={1}>
                                            <Typography variant="h6">Sales System</Typography>
                                            <Typography variant="body2">Software architecture design for Aje Group Sales System. Integration with Orders System and mobile apps. Mobile development with J2ME. SOA Web Services development for integrations requirements.</Typography>
                                            <Chip label="SOA" size="small" className={chips} />
                                            <Chip label="J2EE" size="small" className={chips} />
                                            <Chip label="Postgres" size="small" className={chips} />
                                            <Chip label="Android" size="small" className={chips} />
                                            <Chip label="J2ME" size="small" className={chips} />
                                            <Chip label="REST" size="small" className={chips} />
                                            <Chip label="SEAM" size="small" className={chips} />
                                        </Box>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Box p={0.5}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            2010
                                        </Typography>
                                    </Box>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot  >
                                        <DashboardOutlinedIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={paper}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            Adexus - Systems Analyst
                                        </Typography>
                                        <Box p={1}>
                                            <Typography variant="h6">Stock System</Typography>
                                            <Typography variant="body2">Requirements analysis for different modules in the Stock System. Meetings with stakeholders to capture business requirements. Process diagrams, prototypes, and design diagrams for new system modules.</Typography>
                                            <Chip label="Requirements analysis" size="small" className={chips} />
                                            <Chip label="Genexus" size="small" className={chips} />
                                            <Chip label="Requirements management" size="small" className={chips} />
                                        </Box>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Box p={0.5}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            2009
                                        </Typography>
                                    </Box>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot >
                                        <DashboardOutlinedIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={paper}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            Financiera TFC - Commit - Systems Analyst
                                        </Typography>
                                        <Box p={1}>
                                            <Typography variant="h6">Financial System</Typography>
                                            <Typography variant="body2">Requirements analysis for Financial System. Use case documentation, business analysis, and design diagrams for new modules. Services development to support new modules.</Typography>
                                            <Chip label="J2EE" size="small" className={chips} />
                                            <Chip label="EJB3" size="small" className={chips} />
                                            <Chip label="Oracle" size="small" className={chips} />
                                        </Box>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Box p={0.5}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            2007 - 2009
                                        </Typography>
                                    </Box>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot >
                                        <DashboardOutlinedIcon />
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={paper}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            Blue Star Energy - Software Engineer
                                        </Typography>
                                        <Box p={1}>
                                            <Typography variant="h6">NextStar ERP</Typography>
                                            <Typography variant="body2">Integration services development with ESB patterns to integrate Billing, Accounting, and B2B systems. Development of new REST services to support the Billing process for commercial and residential clients. Mentoring to new team members for development standards, business procedures, and frameworks.</Typography>
                                            <Chip label="Spring" size="small" className={chips} />
                                            <Chip label="Mule ESB" size="small" className={chips} />
                                            <Chip label="SOA" size="small" className={chips} />
                                            <Chip label="REST" size="small" className={chips} />
                                            <Chip label="MySql" size="small" className={chips} />
                                        </Box>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Box p={0.5}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            2004 - 2007
                                        </Typography>
                                    </Box>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot >
                                        <DashboardOutlinedIcon />
                                    </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={paper}>
                                        <Typography variant="h6" component="h1" color="primary">
                                            O&S Consulting - Software Engineer
                                        </Typography>
                                        <Box p={1}>
                                            <Typography variant="h6">Clients</Typography>
                                            <Typography variant="body2">Software development projects in Fondo Mi Vivienda, TOPSA S.A., OSINERMING, ESTUMIN, PERUPETRO, PROMPYME.</Typography>
                                            <Chip label="Java" size="small" className={chips} />
                                            <Chip label="J2EE" size="small" className={chips} />
                                            <Chip label="EJB3" size="small" className={chips} />
                                            <Chip label="Spring" size="small" className={chips} />
                                            <Chip label="Hibernate" size="small" className={chips} />
                                            <Chip label="PHP" size="small" className={chips} />
                                            <Chip label="Oracle" size="small" className={chips} />
                                            <Chip label="MySql" size="small" className={chips} />
                                        </Box>
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                    </Hidden>
                    <Hidden smUp>
                        <Box p={1}>
                            <Paper elevation={3} className={paper}>
                                <Typography variant="h6" component="h1" color="primary">
                                    Globant - Software Architect
                                </Typography>
                                <Typography variant="subtitle" color="primary">
                                    2017 - Today
                                </Typography>
                                <Box p={1}>
                                    <Typography variant="h6">Disney</Typography>
                                    <Typography variant="body2" >Working on Cloud-based application development to integrate different Disney Parks backend services with third-party services for Labor System management.</Typography>
                                    <Chip label="Google Cloud Platform" size="small" className={chips} />
                                    <Chip label="Google App Engine" size="small" className={chips} />
                                    <Chip label="Google Kubernetes Engine" size="small" className={chips} />
                                    <Chip label="Firestore" size="small" className={chips} />
                                    <Chip label="Google Pub/Sub" size="small" className={chips} />
                                    <Chip label="Google Storage" size="small" className={chips} />
                                    <Chip label="Java11" size="small" className={chips} />
                                    <Chip label="Spring Cloud" size="small" className={chips} />
                                    <Chip label="Microservices" size="small" className={chips} />
                                </Box>
                                <Box p={1}>
                                    <Typography variant="subtitle2">AEP Energy USA</Typography>
                                    <Typography variant="body2">Monolith to Microservices migration for an eCommerce system using open source. Microservices development with Spring Cloud.</Typography>
                                    <Chip label="Microservices" size="small" className={chips} />
                                    <Chip label="SpringBoot" size="small" className={chips} />
                                    <Chip label="SpringCloud" size="small" className={chips} />
                                    <Chip label="Swagger" size="small" className={chips} />
                                    <Chip label="Distributed Tracing" size="small" className={chips} />
                                    <Chip label="Docker" size="small" className={chips} />
                                </Box>
                            </Paper>
                        </Box>
                        <Box p={1}>
                            <Paper elevation={3} className={paper}>
                                <Typography variant="h6" component="h1" color="primary">
                                    Sapia (Cosapi Data) - Consultant
                                </Typography>
                                <Typography variant="subtitle" color="primary">
                                    2016 - 2017
                                </Typography>
                                <Box p={1}>
                                    <Typography variant="h6">SUNAT</Typography>
                                    <Typography variant="body2">REST microservices development. Html 5 frontend development sending requests to microservices backend. Visa payment platform. Coaching to the development team to improve their development skills.</Typography>
                                    <Chip label="Dropwizard" size="small" className={chips} />
                                    <Chip label="Docker" size="small" className={chips} />
                                    <Chip label="Kubernetes" size="small" className={chips} />
                                    <Chip label="AngularJS" size="small" className={chips} />
                                    <Chip label="JQuery" size="small" className={chips} />
                                    <Chip label="Bootstrap" size="small" className={chips} />
                                    <Chip label="Nginx" size="small" className={chips} />
                                </Box>
                            </Paper>
                        </Box>
                        <Box p={1}>
                            <Paper elevation={3} className={paper}>
                                <Typography variant="h6" component="h1" color="primary">
                                    Ministerio de Economia del Perú - Consultant
                                </Typography>
                                <Typography variant="subtitle" color="primary">
                                    2014 - 2016
                                </Typography>
                                <Box p={1}>
                                    <Typography variant="h6">SIAF 2</Typography>
                                    <Typography variant="body2">SOA architecture design and implementation with open source. System integrations with ESB.</Typography>
                                    <Chip label="SOA" size="small" className={chips} />
                                    <Chip label="Oracle" size="small" className={chips} />
                                    <Chip label="J2EE" size="small" className={chips} />
                                    <Chip label="Microservices" size="small" className={chips} />
                                    <Chip label="Drools" size="small" className={chips} />
                                </Box>
                            </Paper>
                        </Box>
                        <Box p={1}>
                            <Paper elevation={3} className={paper}>
                                <Typography variant="h6" component="h1" color="primary">
                                    AJE Group - Team Lead
                                </Typography>
                                <Typography variant="subtitle" color="primary">
                                    2010 - 2013
                                </Typography>
                                <Box p={1}>
                                    <Typography variant="h6">Sales System</Typography>
                                    <Typography variant="body2">Software architecture design for Aje Group Sales System. Integration with Orders System and mobile apps. Mobile development with J2ME. SOA Web Services development for integrations requirements.</Typography>
                                    <Chip label="SOA" size="small" className={chips} />
                                    <Chip label="J2EE" size="small" className={chips} />
                                    <Chip label="Postgres" size="small" className={chips} />
                                    <Chip label="Android" size="small" className={chips} />
                                    <Chip label="J2ME" size="small" className={chips} />
                                    <Chip label="REST" size="small" className={chips} />
                                    <Chip label="SEAM" size="small" className={chips} />
                                </Box>
                            </Paper>
                        </Box>
                        <Box p={1}>
                            <Paper elevation={3} className={paper}>
                                <Typography variant="h6" component="h1" color="primary">
                                    Adexus - Systems Analyst
                                </Typography>
                                <Typography variant="subtitle" color="primary">
                                    2010
                                </Typography>
                                <Box p={1}>
                                    <Typography variant="h6">Stock System</Typography>
                                    <Typography variant="body2">Requirements analysis for different modules in the Stock System. Meetings with stakeholders to capture business requirements. Process diagrams, prototypes, and design diagrams for new system modules.</Typography>
                                    <Chip label="Requirements analysis" size="small" className={chips} />
                                    <Chip label="Genexus" size="small" className={chips} />
                                    <Chip label="Requirements management" size="small" className={chips} />
                                </Box>
                            </Paper>
                        </Box>
                        <Box p={1}>
                            <Paper elevation={3} className={paper}>
                                <Typography variant="h6" component="h1" color="primary">
                                    Financiera TFC - Commit - Systems Analyst
                                </Typography>
                                <Typography variant="subtitle" color="primary">
                                    2009
                                </Typography>
                                <Box p={1}>
                                    <Typography variant="h6">Financial System</Typography>
                                    <Typography variant="body2">Requirements analysis for Financial System. Use case documentation, business analysis, and design diagrams for new modules. Services development to support new modules.</Typography>
                                    <Chip label="J2EE" size="small" className={chips} />
                                    <Chip label="EJB3" size="small" className={chips} />
                                    <Chip label="Oracle" size="small" className={chips} />
                                </Box>
                            </Paper>
                        </Box>
                        <Box p={1}>
                            <Paper elevation={3} className={paper}>
                                <Typography variant="h6" component="h1" color="primary">
                                    Blue Star Energy - Software Engineer
                                </Typography>
                                <Typography variant="subtitle" color="primary">
                                    2007 - 2009
                                </Typography>
                                <Box p={1}>
                                    <Typography variant="h6">NextStar ERP</Typography>
                                    <Typography variant="body2">Integration services development with ESB patterns to integrate Billing, Accounting, and B2B systems. Development of new REST services to support the Billing process for commercial and residential clients. Mentoring to new team members for development standards, business procedures, and frameworks.</Typography>
                                    <Chip label="Spring" size="small" className={chips} />
                                    <Chip label="Mule ESB" size="small" className={chips} />
                                    <Chip label="SOA" size="small" className={chips} />
                                    <Chip label="REST" size="small" className={chips} />
                                    <Chip label="MySql" size="small" className={chips} />
                                </Box>
                            </Paper>
                        </Box>
                        <Box p={1}>
                            <Paper elevation={3} className={paper}>
                                <Typography variant="h6" component="h1" color="primary">
                                    O&S Consulting - Software Engineer
                                </Typography>
                                <Typography variant="subtitle" color="primary">
                                    2004 - 2007
                                </Typography>
                                <Box p={1}>
                                    <Typography variant="h6">Clients</Typography>
                                    <Typography variant="body2">Software development projects in Fondo Mi Vivienda, TOPSA S.A., OSINERMING, ESTUMIN, PERUPETRO, PROMPYME.</Typography>
                                    <Chip label="Java" size="small" className={chips} />
                                    <Chip label="J2EE" size="small" className={chips} />
                                    <Chip label="EJB3" size="small" className={chips} />
                                    <Chip label="Spring" size="small" className={chips} />
                                    <Chip label="Hibernate" size="small" className={chips} />
                                    <Chip label="PHP" size="small" className={chips} />
                                    <Chip label="Oracle" size="small" className={chips} />
                                    <Chip label="MySql" size="small" className={chips} />
                                </Box>
                            </Paper>
                        </Box>
                    </Hidden>
                </Paper>
            </Container>
        </Layout>
    )
}

export default ExperiencePage
